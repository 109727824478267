<header
  class="header"
  [class.set--inverted]="inverted"
  [class.set--audio]="!playerVars.muted"
  [class.set--inview]="inviewPort"
  [class.set--intro]="intro"
  [class.no--content]="!title"
  [class.set--animation]="animPlay"
  [class.set--fixed-image]="fixedImage"
  [class.set--image-contain]="imageContain"
  [ngClass]="cssClass"
  (fstrInViewport)="inViewChange($event)"
  [once]="false"
  [threshold]="0"
  #header
>
  <figure class="header__crop" [class.header__crop--audio-on]="!playerVars.muted">
    <ng-container *ngIf="fixedImage">
      <div
        class="aspect-ratio-element header__crop__wrapper"
        appParallax
        [loadParallax]="fixedImage && inviewPort"
        [parallaxRatio]="parallaxRatio"
      >
        <!-- image set contain false -->
        <app-image
          *ngIf="imageUrl"
          [src]="imageUrl"
          [width]="imageSizes.w * 2"
          [height]="imageSizes.h * 2"
          [mode]="imageContain ? 'pad' : 'crop'"
          [scale]="'both'"
          [cssClasses]="'header__image' + (videoLoaded ? ' video--loaded' : '')"
          [alt]="title || subtitle || imageUrl"
        ></app-image>

        <!--<img
          [loading]="lazyLoading"
          [alt]="title || subtitle || imageUrl"
          [class.video--loaded]="videoLoaded"
          class="header__image"
          [src]="imageUrl | resize: imageSizes.w:imageSizes.h:imageAnchor:type:'webp'"
          *ngIf="imageUrl && !imageContain"
        />-->

        <!-- image set contain true -->
        <!--<img
          [loading]="lazyLoading"
          [alt]="title || subtitle || imageUrl"
          [class.video--loaded]="videoLoaded"
          class="header__image"
          [src]="imageUrl + '?maxWidth=imageSizes.w&maxHeight=imageSizes.h'"
          *ngIf="imageUrl && imageContain"
        />-->

        <fstr-html5-video
          class="header__video"
          [class.set--player]="videoLoaded"
          [playerVars]="playerVars"
          (ready)="player($event)"
          *ngIf="videoBg && isBrowser"
          [src]="videoBg"
        ></fstr-html5-video>
      </div>
    </ng-container>

    <ng-container *ngIf="!fixedImage">
      <div class="container-fluid" *ngIf="imageUrl && imageContain">
        <div class="row justify-content-center">
          <div class="col-17 col-md-15 col-lg-10 text-center">
            <!--<img
              *ngIf="imageUrl"
              [loading]="lazyLoading"
              [class.video--loaded]="videoLoaded"
              [alt]="title || subtitle || imageUrl"
              [src]="imageUrl + '?maxWidth=imageSizes.w&maxHeight=imageSizes.h'"
            />-->

            <app-image
              *ngIf="imageUrl"
              [src]="imageUrl"
              [width]="imageSizes.w"
              [height]="imageSizes.h"
              [mode]="imageContain ? 'pad' : 'crop'"
              [scale]="'max'"
              [cssClasses]="videoLoaded ? ' video--loaded' : ''"
              [alt]="title || subtitle || imageUrl"
            ></app-image>
          </div>
        </div>
      </div>

      <div class="aspect-ratio-element" *ngIf="!imageContain || videoBg">
        <!--<img
          [loading]="lazyLoading"
          *ngIf="imageUrl && !imageContain"
          [class.video--loaded]="videoLoaded"
          class="header__image"
          [alt]="title || subtitle || imageUrl"
          [src]="imageUrl | resize : imageSizes.w : imageSizes.h : imageAnchor"
        />-->

        <app-image
          [cssClasses]="'header__image' + (videoLoaded ? ' video--loaded' : '')"
          *ngIf="imageUrl"
          [loading]="lazyLoading"
          [src]="imageUrl"
          [priority]="true"
          [width]="imageSizes.w"
          [height]="imageSizes.h"
          [anchor]="imageAnchor"
          [mode]="'crop'"
          [scale]="'both'"
          [alt]="title || subtitle || imageUrl"
        ></app-image>

        <fstr-html5-video
          class="header__video"
          [class.set--player]="videoLoaded"
          [playerVars]="playerVars"
          (ready)="player($event)"
          *ngIf="videoBg && isBrowser"
          [src]="videoBg"
        ></fstr-html5-video>
        <div class="header__video__button" *ngIf="videoBg && asVideoPlayer && !videoPlayed">
          <button (click)="playVideo()">
            <fstr-icon anchor="icon-play"></fstr-icon>
            <strong>Play video</strong>
          </button>
        </div>
      </div>
    </ng-container>
  </figure>
  <div class="header__buttons">
    <div class="container-fluid">
      <!-- <button
        class="button-audio header__toggle-audio"
        [ngClass]="inverted ? 'color-black' : 'color-white'"
        [class.header__toggle-audio--audio-on]="!playerVars.muted"
        *ngIf="videoLoaded"
        (click)="toggleAudio()"
      >
        <fstr-icon class="icon-md icon--audio-off" [anchor]="'icon-volume'"></fstr-icon>
        <fstr-icon class="icon-md icon--audio-on" [anchor]="'icon-volume-muted'"></fstr-icon>
      </button> -->

      <a
        *ngIf="button?.routerLink"
        [ngClass]="inverted ? 'color-black' : 'color-white'"
        class="button button--border router"
        [routerLink]="removeHashFromString"
        [fragment]="hashFromString"
      >
        <span>{{ button.title }}</span>
      </a>
      <a
        *ngIf="!button?.routerLink && button?.url"
        [ngClass]="inverted ? 'color-black' : 'color-white'"
        class="button button--border"
        [href]="button.url"
      >
        <span>{{ button.title }}</span>
      </a>
      <!-- <button class="header__scroll-button" (click)="scrollDown()">
        <fstr-icon class="icon-sm" [anchor]="'icon-chevron-down'"></fstr-icon>
        <fstr-icon class="icon-sm" [anchor]="'icon-chevron-down'"></fstr-icon>
      </button> -->
    </div>
  </div>
  <div class="header__content">
    <h1 class="h1 header__content__title type-jumbo-xl" *ngIf="title" [innerHTML]="title"></h1>
    <h2 class="header__content__subtitle text-white" [innerHTML]="subtitle" *ngIf="subtitle"></h2>
  </div>

  <ng-content></ng-content>
  <footer class="header__footer container-lg" *ngIf="intro">
    <p class="type-body-large">{{ intro }}</p>
  </footer>
</header>
